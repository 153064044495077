<template>
	<div class="clearBox">
		<ayl-berad-nav :nav="nav"></ayl-berad-nav>
		<div class="content-box mt20px">
			<ayl-main title="基本信息" :isCollapse="false">
				<div slot="main" class="p24px">
					<el-form :model="form" :rules="rules" ref="form" :inline="true" label-position="left"
						label-width="108px">
						<el-form-item label="用户账号" prop="username">
							<el-input v-model.trim="form.username" :maxlength="64" placeholder="请输入用户账号" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="用户名称" prop="nickName">
							<el-input v-model.trim="form.nickName" :maxlength="32" clearable placeholder="请输入用户名称">
							</el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="phone">
							<el-input v-model.trim="form.phone" :maxlength="11" clearable placeholder="请输入手机号码">
							</el-input>
						</el-form-item>
						<el-form-item label="状态" prop="status">
							<el-select v-model="form.status" placeholder="请选择">
								<el-option v-for="(item, idx) in $enums.userList.list" :key="idx" :label="item.label"
									:value="item.value" />
							</el-select>
						</el-form-item>
						<el-form-item label="用户级别" prop="userLevel">
							<el-select v-model="form.userLevel" placeholder="请选择">
								<el-option v-for="(item, idx) in $enums.userLevel.list" :key="idx" :label="item.label"
									:value="item.value" />
							</el-select>
						</el-form-item>
						<el-form-item label="所属公司" prop="permissionList">
							<el-cascader v-model="form.permissionList" :options="addUser" :show-all-levels="false"
								:props="props" collapse-tags ref="tree" @change="handleChange"></el-cascader>
						</el-form-item>
						<el-form-item label="角色分配" prop="roleIdentity">
							<el-select v-model="form.roleIdentity" placeholder="请选择" multiple collapse-tags>
								<el-option v-for="(item, idx) in form.roleList" :key="idx" :label="item.roleName"
									:disabled="item.checked" :value="item.roleIdentity" />
							</el-select>
						</el-form-item>
						<el-form-item label="用户类型" prop="userTypeLists">
							<el-select v-model="form.userTypeLists" placeholder="请选择">
								<el-option v-for="(item, idx) in form.userTypeList" :key="idx" :label="item"
									:value="item" />
							</el-select>
						</el-form-item>
						<el-form-item label="禁止修改密码" prop="allowChangePassword" style="padding-right: 174px">
							<el-radio-group v-model="form.allowChangePassword">
								<el-radio label="是"></el-radio>
								<el-radio label="否"></el-radio>
							</el-radio-group>
						</el-form-item>
					</el-form>
				</div>
			</ayl-main>
			<ayl-main title="其他信息(选填)" :isCollapse="false">
				<div slot="main" class="p24px">
					<el-form :model="form" :rules="rules" :inline="true" label-position="left" label-width="108px">
						<el-form-item label="邮箱">
							<el-input v-model.trim="form.email" placeholder="请输入邮箱" :maxlength="64"></el-input>
						</el-form-item>
						<el-form-item label="" style="visibility:hidden">
							<el-input></el-input>
						</el-form-item>
						<el-form-item label="备注">
							<el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容"
								:maxlength="64" v-model.trim="form.remark">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
			</ayl-main>
			<div class="tac" style="margin-top: 40px">
				<el-button type="primary" @click="submitForms" v-if="allowAdd === true" :loading="submitLoading"
					:disabled="isDisable">确认</el-button>
				<el-button type="info" v-if="allowAdd === false">确认</el-button>
				<el-button style="margin-left: 30px"
					@click="$router.push('/sanitation-system-management/user-manage/user-manages')">返回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		constants
	} from 'crypto';
	export default {
		data() {
			return {
				isDisable: false,
				nav: [{
					name: "新增用户",
					path: ""
				}, ],
				props: {
					expandTrigger: 'hover',
					checkStrictly: true
				},
				addUser: [],
				allowAdd: null, //是否具有权限新增用户 
				form: {
					permissionList: null,
					roleList: [], //角色分配
					roleIdentity: [], //角色分配选中集合
					userTypeList: [], //用户类型
					userTypeLists: null, //用户类型选中集合
					username: null, //用户账号
					nickName: null, //用户名称
					phone: null, //电话号码
					email: null, //邮箱
					status: null, //状态
					userLevel: null, //用户级别
					allowChangePassword: null, //是否修改密码
					remark: null,
				},
				companyId: null,
				rules: {
					username: [{
							required: true,
							message: '请输入用户账户',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 64,
							required: true,
							message: '请正确输入1-64位的用户账号',
							trigger: 'blur'
						},
						{
							validator: this.$validator.isContainBlank
						},
					],
					nickName: [{
							required: true,
							message: '请输入用户名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 32,
							required: true,
							message: '请正确输入1-32位的用户名称',
							trigger: 'blur'
						},
						{
							validator: this.$validator.isContainBlank
						},
					],
					phone: [{
						required: true,
						validator: this.$validator.validatePhone,
						trigger: 'blur'
					}, {
						validator: this.$validator.isContainBlank
					}],
					status: [{
						required: true,
						message: '请选择状态',
						trigger: 'change'
					}],
					userLevel: [{
						required: true,
						message: '请选择级别',
						trigger: 'change'
					}],
					permissionList: [{
						required: true,
						message: '请选择公司',
						trigger: 'change'
					}, ],
					roleIdentity: [{
						required: true,
						message: '请分配角色',
						trigger: 'change'
					}, ],
					userTypeLists: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'change'
					}],
					allowChangePassword: [{
						required: true,
						message: '请选择是或否',
						trigger: 'change'
					}],
				},
				submitLoading: false
			}
		},
		methods: {
			async handleChange(node) {
				this.form.roleIdentity = []
				this.form.userTypeLists = null
				let roleData = []
				this.form.permissionList = node


				//根据选择的公司确定接下来的操作
				let size = this.form.permissionList.length
				this.companyId = this.form.permissionList[size - 1]
				roleData = await this.$api.getListHandle({
					companyId: this.form.permissionList[size - 1]
				})
				this.form.roleList = roleData.roleList

				//用户级别
				this.form.userTypeList = roleData.userTypeList
				//是否具有权限新增用户 
				this.allowAdd = roleData.allowAdd
				if (this.allowAdd === true) {
					return this.allowAdd
				} else {
					this.$message({
						message: '同一个公司只能有一个主账号',
						type: 'error'
					})
					return this.allowAdd
				}
				for (let i in roleData.roleList) {
					if (roleData.roleList[i].checked === true) {
						this.form.roleIdentity.push(roleData.roleList[i].roleIdentity)
					} else {
						return this.form.roleIdentity
					}
				}
			},

			async submitInsurance() {
				let checkEmail = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
				if ((this.form.email !== null && this.form.email !== '') && !checkEmail.test(this.form.email)) {
					this.$message({
						message: '请输入正确邮箱格式',
						type: 'error'
					})
					this.form.email = ''
				} else {
					if ((this.form.username) !== '' && this.form.nickName !== '' && this.form.status !== '' && this
						.form.phone !== '' && this.form.userTypeLists !== '' && this.form.userLevel !== '' && this.form
						.allowChangePassword !== null && this.form.roleIdentity.length !== 0) {
						await this.$api.getListAdd({
							userInfo: {
								username: this.form.username,
								nickName: this.form.nickName,
								phone: this.form.phone,
								email: this.form.email,
								status: this.form.status,
								companyId: this.companyId,
								userType: this.form.userTypeLists,
								userLevel: this.form.userLevel,
								allowChangePassword: this.form.allowChangePassword,
								roleList: this.form.roleIdentity,
								remark: this.form.remark,
							}
						})
						await this.$notify({
							title: '成功',
							message: '新增用户成功',
							type: 'success'
						})
						await this.$router.push('/sanitation-system-management/user-manage/user-manages')
					} else {
						await this.$message({
							message: '新增用户失败,请输入必填字段',
							type: 'error'
						});
					}
				}
				this.isDisable = true
				setTimeout(() => {
					this.isDisable = false
				}, 3000)
			},
			submitForms() {
				this.$refs.form.validate(async valid => {
					if (!valid) return
					this.submitLoading = true
					try {
						this.submitInsurance()
					} catch (e) {}
					this.submitLoading = false
				})
			},
		},
		async mounted() {
			this.addUser = await this.$api.getListCompany({})
			//console.log(this.addUser)
		}
	}
</script>
<style lang='sass' scoped>
.clearBox
  /deep/ .el-cascader__tags .el-tag .el-icon-close
    display: none
  /deep/.el-button--primary 
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)
</style>